//import { Create } from "@mui/icons-material";
//import { IconButton } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { GridColDef as GridColDefPremium } from "@mui/x-data-grid-premium";
import { format, formatDistanceToNow } from "date-fns";
import { Link as RouterLink } from "react-router-dom";
//local
import {
  CheckCircleFilled,
  HtmlWrapper,
  ChatIcon,
  EditUnderlineIcon,
  Modal,
  EditReportForm,
  Tooltip,
  JoinZoomMeetingButton,
  FileIcon,
  UserAvatar,
  CalendarIcon,
  QuestionIcon,
  LockIcon,
  WrenchIcon,
  ClipboardIcon,
  PriorityChip,
  UserActivityButton,
  StatusChip,
  SessionIcon,
  OpenInNewIcon,
  MediaDownload,
  DeleteLinedIcon,
  ConfirmDeleteModal,
  ClockIcon,
  DuplicateReportIcon,
} from "./components";
import { ChipStyled, EllipsisTypographyStyled } from "./components/styled";
//import AdminPages from "./pages/admin";
import { actions } from "./state/rtk-query";
import {
  GetRolesPermissions,
  validateRoles,
  getRoleInfoById,
  EXTERNAL_ADMINS,
  capitalizeSentence,
  formatDate,
  filterBoolean,
  PreviewFileExtensions,
  convertToHoursAndMinutes,
  fixJsFormattedDate,
} from "./lib";
import {
  IconButton,
  List,
  ListItemText,
  Typography,
  Stack,
  useTheme,
  Button,
  Link,
  Box,
  Tooltip as MuiTooltip,
} from "@mui/material";
import {
  GetUserApiResponse,
  GetUsersApiResponse,
} from "./state/rtk-query/state/user";
import { GetAnnouncementsApiResponse } from "./state/rtk-query/state/announcements";
import { GetOrgsApiResponse } from "./state/rtk-query/state/organization";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, MouseEvent, useCallback, useMemo, useState } from "react";
import { authSelectors, uiActions } from "./state";
import { useMobile } from "./themes";
import {
  useAccountManagersList,
  useCombinedUsersList,
  useConsultantsList,
  useLicensesList,
  useOrgsList,
  useStatusesList,
  useTopicsList,
  usePositionsList,
} from "./components/hooks/useLists";
import { stripHtml } from "string-strip-html";
import { ToggleActionPendingButton } from "./components/buttons/ToggleActionPendingButton";
import {
  useMemoObj,
  useOpenTaskDrawer,
  useQueryParam,
} from "./components/hooks";
import { useRestoreTaskMutation } from "./state/rtk-query/state/tasks";
import { useLazyGetSignedUrlByMediaIdQuery } from "./state/rtk-query/state/media";
import { useList } from "./components/hooks/useNewLists";
const {
  useDeleteMediaMutation,
  //useDeleteUserMutation
} = actions;
// const UserActions = (params: GridRenderCellParams) => {
//   const [deleteUser] = useDeleteUserMutation();
//   return (
//     <>
//       <Link to={AdminPages.editUsers.path.replace(":id", params.id.toString())}>
//         <IconButton color="primary" size="small">
//           <Create />
//         </IconButton>
//       </Link>

//       <ConfirmDeleteDialog
//         handleConfirm={() => deleteUser({ id: Number(params.id) })}
//       />
//     </>
//   );
// };
interface OpenTaskButtonProps {
  taskId?: number;
  code?: string;
  title?: string;
  ellipsis?: boolean;
}
const OpenTaskButton = ({
  taskId,
  code,
  title,
  ellipsis = true,
}: OpenTaskButtonProps) => {
  const openTask = useOpenTaskDrawer();
  const openDrawer = (
    e: React.MouseEvent<HTMLButtonElement>,
    taskId?: number,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (taskId) {
      openTask(taskId);
    }
  };
  const label = `[${code ?? ""}] - ${title ?? ""}`;
  return (
    <Button
      onClick={(e) => openDrawer(e, taskId)}
      color="primary"
      disableRipple
      sx={{ p: 0 }}
    >
      {ellipsis ? (
        <EllipsisTypographyStyled>{label}</EllipsisTypographyStyled>
      ) : (
        <Typography fontSize={14} fontWeight={500}>
          {label}
        </Typography>
      )}
    </Button>
  );
};

const TaskMediaActions = (params: GridRenderCellParams) => {
  const [deleteMedia] = useDeleteMediaMutation();
  const [getMediaSignedUrl] = useLazyGetSignedUrlByMediaIdQuery();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const { signedUrl, media_id, media, task, ogExt } = params.row ?? {};
  const orgId = task?.org_id;
  const canPreview = PreviewFileExtensions.find((e) => e === ogExt);

  const openImgInNewTab = async () => {
    window.open(signedUrl);
  };

  const downloadFile = async () => {
    if (!media_id) return;
    const fullImg = await getMediaSignedUrl({
      id: media_id,
      download: true,
      orgId,
    }).unwrap();
    if (!fullImg) return;
    const link = document.createElement("a");
    link.href = fullImg?.signedUrl;
    link.click();
  };

  const deleteMediaClick = useCallback(async () => {
    if (media_id) {
      await deleteMedia({ id: media_id, orgId });
    }
  }, [media_id, orgId]);
  const openDeleteModalClick = () => {
    setOpenDeleteModal((o) => !o);
  };
  return (
    <>
      <Tooltip
        title={canPreview ? "" : "Preview is unavailable for this file type"}
      >
        <Box>
          <IconButton
            onClick={openImgInNewTab}
            disabled={!canPreview}
            color="primary"
            size="small"
          >
            <OpenInNewIcon />
          </IconButton>
        </Box>
      </Tooltip>

      <IconButton onClick={downloadFile} size="small">
        <MediaDownload />
      </IconButton>
      <IconButton onClick={openDeleteModalClick} color="error" size="small">
        <DeleteLinedIcon />
      </IconButton>
      {media_id && media?.filename && (
        <ConfirmDeleteModal
          open={!!openDeleteModal}
          onClose={openDeleteModalClick}
          handleConfirm={deleteMediaClick}
          titleText={`Are you sure you want to delete ${media.filename}`}
        />
      )}
    </>
  );
};
const ReportActions = (params: GridRenderCellParams) => {
  const [openEditForm, setOpenEditForm] = useState(false);
  const [duplicateReport, setDuplicateReport] = useState(false);
  const isAdmin = useSelector(authSelectors.isAdmin);
  const onClose = useCallback(() => {
    setOpenEditForm(false);
    setDuplicateReport(false);
  }, [openEditForm, duplicateReport]);

  const onClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>, duplicateReportClick: boolean) => {
      e.preventDefault();
      e.stopPropagation();
      setOpenEditForm(!openEditForm);
      setDuplicateReport(duplicateReportClick);
    },
    [openEditForm, duplicateReport],
  );

  return (
    <>
      {isAdmin && (
        <IconButton onClick={(e) => onClick(e, true)} disabled={!isAdmin}>
          <DuplicateReportIcon />
        </IconButton>
      )}
      <IconButton onClick={(e) => onClick(e, false)} disabled={!isAdmin}>
        <EditUnderlineIcon />
      </IconButton>
      <Modal
        open={openEditForm}
        onClose={onClose}
        pcWidth={"80%"}
        sx={{ height: "95%", overflow: "auto" }}
      >
        <EditReportForm
          onClose={onClose}
          reportId={params.row.id}
          reportName={params.row.name}
          roles={params.row.report_permissions.map(
            (r: { role_id: number }) => r.role_id,
          )}
          duplicateReport={duplicateReport}
        />
      </Modal>
    </>
  );
};

const RestoreButton = (
  params: GridRenderCellParams,
  archived: boolean,
  restoreFn: ({ id, orgId }: { id: number; orgId: number }) => Promise<any>,
  type: "task" | "session",
) => {
  const dispatch = useDispatch();
  const isArchived = params.row?.deleted_at;
  const orgId = params.row?.org_id;
  const id = params.row?.id;

  const onClick = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (archived && id && orgId) {
        await restoreFn({ id, orgId });
        dispatch(uiActions.showSuccess(`${type} restored`));
      }
    },
    [isArchived, orgId, id],
  );
  return isArchived ? <Button onClick={onClick}>Restore</Button> : <></>;
};

const _DiscussionActions = (params: GridRenderCellParams) => {
  const [taskId, setTaskId] = useQueryParam("task");
  const id = params.row.id;

  const drawerClick = useCallback(async () => {
    const shouldOpen = Boolean(taskId && id);
    if (shouldOpen) {
      setTaskId(id);
    } else {
      setTaskId();
    }
  }, [id, taskId, setTaskId]);
  // TODO: Find-out what the tasks page looks like and if there should be a notifications badge
  return (
    <IconButton color="primary" size="small" onClick={drawerClick}>
      <ChatIcon />
    </IconButton>
  );
};
const RawHtmlWrapper = ({
  params,
  property,
  strip,
  value: val,
}: {
  params: GridRenderCellParams;
  property: keyof GridRenderCellParams["row"];
  value?: string;
  strip?: boolean;
}) => {
  const value = val || params.row[property];
  const temp = document.createElement("div");
  temp.innerHTML = value;
  const textVal = temp.textContent || temp.innerText || "";
  return (
    <Tooltip
      title={textVal}
      props={{
        placement: "top",
        sx: {
          width: "fit-content",
        },
      }}
    >
      <span
        style={{
          width: "fit-content",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {strip ? (
          stripHtml(value || "").result
        ) : (
          <HtmlWrapper message={value} ellipsis />
        )}
      </span>
    </Tooltip>
  );
};

function formatPhone(phone?: string, cc?: string) {
  const _phone = phone
    ? `(${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(
        6,
      )}`
    : "";
  const _cc = cc ? `+${cc} ` : "";
  return _cc + _phone;
}

const idColumn: GridColDef = {
  field: "id",
  headerName: "Id",
  type: "number",
  minWidth: 100,
};
const emailColumn: GridColDef = {
  field: "email",
  headerName: "Email",
  type: "string",
  width: 250,
};

function getOptionValue(option: any) {
  return option?.id;
}

function getOptionLabel(option: any) {
  return option?.name;
}

export function useOrgColumns(
  onEditClick?: (row: GetOrgsApiResponse["rows"][0]) => any,
): GridColDef<GetOrgsApiResponse["rows"][0]>[] {
  const isMobile = useMobile();
  const { consultants } = useConsultantsList();
  const { accountManagers } = useAccountManagersList();
  const licenses = useList("licenses");
  const licenseStatuses = useList("licenseStatuses");
  return useMemo(() => {
    const orgActions = (params: GridRenderCellParams) => {
      const onClick = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();
          e.stopPropagation();
          onEditClick && onEditClick(params.row);
        },
        [onEditClick],
      );

      return (
        <IconButton onClick={onClick}>
          <EditUnderlineIcon />
        </IconButton>
      );
    };
    type L = Record<string, number[]>;
    const { ls, l } = licenseStatuses.list.reduce(
      ({ ls, l }, cur) => {
        if (!ls[cur.name]) {
          ls[cur.name] = [];
        }
        const license = licenses.get(cur.license_id);
        if (!l[license?.name]) {
          l[license?.name] = [];
        }
        ls[cur.name].push(cur.id);
        l[license?.name].push(cur.id);
        return { ls, l };
      },
      { ls: {}, l: {} } as { ls: L; l: L },
    );
    const licenseStatusValues = Object.keys(ls).map((label) => ({
      label,
      value: ls[label],
    }));
    const licenseValues = Object.keys(l).map((label) => ({
      label,
      value: l[label],
    }));
    return [
      {
        field: "name",
        headerName: "Organization",
        flex: isMobile ? undefined : 1.5,
      },
      {
        field: "consultant_id",
        headerName: "Consultant",
        flex: isMobile ? undefined : 1,
        type: "singleSelect",
        valueOptions: consultants,
        getOptionLabel,
        getOptionValue,
        sortable: false,
      },
      {
        field: "sub_consultant_id",
        headerName: "Sub Consultant",
        flex: isMobile ? undefined : 1,
        type: "singleSelect",
        valueOptions: consultants,
        getOptionLabel,
        getOptionValue,
        sortable: false,
      },
      {
        field: "account_manager_id",
        headerName: "Account manager",
        flex: isMobile ? undefined : 1,
        type: "singleSelect",
        valueOptions: accountManagers,
        getOptionLabel,
        getOptionValue,
        sortable: false,
      },
      {
        field: "license_status_id_",
        headerName: "License",
        type: "singleSelect",
        valueGetter: (p) => {
          const licenseStatus = licenseStatuses.get(p.row.license_status_id);
          const name = licenses.get(licenseStatus?.license_id)?.name;
          if (!name) return "";
          return l[name] || "";
        },
        valueOptions: licenseValues,
        minWidth: isMobile ? 0 : 150,
        flex: isMobile ? undefined : 1.3,
        sortable: false,
      },
      {
        field: "license_status_id",
        headerName: "Status",
        type: "singleSelect",
        valueGetter: (p) => {
          const name = licenseStatuses.get(p.row.license_status_id!)?.name;
          if (!name) return "";
          return ls?.[name] || [null];
        },
        minWidth: isMobile ? 0 : 150,
        valueOptions: licenseStatusValues,
      },
      {
        field: "open_tasks",
        headerName: "open tasks",
        flex: isMobile ? undefined : 0.7,
        filterable: false,
        sortable: false,
      },
      {
        field: "website",
        headerName: "Website",
        flex: isMobile ? undefined : 1,
      },
      {
        field: "phone",
        flex: isMobile ? undefined : 1,
        valueGetter(r) {
          return formatPhone(r.row.phone, r.row.country_code!);
        },
      },
      {
        field: "actions",
        headerName: "",
        width: 25,
        align: "right",
        headerAlign: "right",
        renderCell: orgActions,
        type: "actions",
      },
    ] as GridColDef<GetOrgsApiResponse["rows"][0]>[];
  }, [
    isMobile,
    consultants,
    accountManagers,
    licenseStatuses.list,
    licenses.list,
    onEditClick,
  ]);
}

export function useTaskColumns(
  inIndividualOrg?: boolean,
  isPageSubSection = false,
  pageRendered = false,
  archived = false,
): GridColDef[] {
  const isMobile = useMobile();
  const { palette } = useTheme();
  const { topics } = useTopicsList();
  const [restoreTask] = useRestoreTaskMutation();
  const { orgs } = useOrgsList();
  const { users, getUser } = useCombinedUsersList(-1);
  const { statuses, getStatus } = useStatusesList("all_tasks");
  const { getStatusByPhase } = useStatusesList("session");
  const sessionBlocked = getStatusByPhase("blocked");
  const typeValues = [
    { label: "Task", value: "task" },
    { label: "Internal Task", value: "internal_task" },
    { label: "Quick Question", value: "quick_question" },
    { label: "Custom Work", value: "custom_work" },
  ];

  const aAndCProps = useMemoObj({
    width: 24,
    align: "center",
    disableColumnMenu: true,
  });

  const attachmentsAndComments = useMemo(
    () => [
      {
        field: "media_count",
        headerName: "Attachments",
        renderHeader: () => (
          <Tooltip title={"Attachments"}>
            <IconButton disableRipple sx={{ p: 0 }}>
              <FileIcon />
            </IconButton>
          </Tooltip>
        ),
        sortable: false,
        filterable: false,
        ...aAndCProps,
      },
      {
        field: "task_chat_count",
        headerName: "Comments",
        renderHeader: () => (
          <Tooltip title={"Comments"}>
            <IconButton disableRipple sx={{ p: 0 }}>
              <ChatIcon />
            </IconButton>
          </Tooltip>
        ),
        sortable: false,
        filterable: false,
        ...aAndCProps,
      },
    ],
    [],
  );

  const statusNamesObj = statuses.reduce((acc, cur) => {
    if (!acc[cur.name]) acc[cur.name] = [];
    acc[cur.name].push(cur.id);
    return acc;
  }, {} as Record<string, number[]>);

  const statusGroupedByName = Object.entries(statusNamesObj).map(
    ([name, ids]) => ({ label: name, value: ids }),
  );

  return useMemo<GridColDef[]>(() => {
    return filterBoolean([
      !inIndividualOrg
        ? {
            field: "org_id",
            headerName: "Organization",
            width: 190,
            sortable: false,
            type: "singleSelect",
            valueOptions: orgs,
            getOptionLabel,
            getOptionValue,
          }
        : undefined,
      {
        field: "id",
        headerName: "Id",
      },
      {
        field: "type",
        headerName: "Type",
        width: 40,
        // flex: isMobile ? undefined : 1,
        type: "singleSelect",
        valueOptions: typeValues,
        renderCell: (p) => {
          const type = p.row?.type;
          const hasActiveSession = p.row?.has_active_session ?? false;
          const isPendingSession =
            hasActiveSession && !p.row?.session_start_time;
          const { palette } = useTheme();
          return (
            <Tooltip
              title={
                isPendingSession
                  ? "Pending Session"
                  : hasActiveSession && type === "task"
                  ? ""
                  : capitalizeSentence(type.split("_").join(" "))
              }
            >
              <span>
                {type === "custom_work" ? (
                  <WrenchIcon color={palette.info.main} />
                ) : type === "quick_question" ? (
                  <QuestionIcon color={palette.orange[600]} />
                ) : type === "internal_task" ? (
                  <LockIcon color={palette.warning.main} />
                ) : hasActiveSession ? (
                  isPendingSession ? (
                    <CalendarIcon color={palette.grey[700]} />
                  ) : (
                    <SessionIcon
                      start_time={p.row?.session_start_time}
                      status_id={p.row?.session_status_id}
                    />
                  )
                ) : (
                  <ClipboardIcon />
                )}
              </span>
            </Tooltip>
          );
        },
      },
      {
        field: "topic_id",
        headerName: "Topic",
        width: 115,
        // flex: isMobile ? undefined : 1,
        type: "singleSelect",
        valueOptions: topics,
        getOptionLabel,
        getOptionValue,
        sortable: false,
      },
      {
        field: "title",
        headerName: "Title",
        width: isPageSubSection ? 175 : 250,
        renderCell: (p) => <RawHtmlWrapper params={p} property="title" />,
      },
      {
        field: "description",
        headerName: "Description",

        width: isPageSubSection ? 210 : 310,
        renderCell: (p) => (
          <RawHtmlWrapper params={p} property="description" strip />
        ),
      },
      {
        field: "status_id",
        headerName: "Status",
        width: isPageSubSection ? 90 : 100,
        type: "singleSelect",
        valueOptions: statusGroupedByName,
        valueGetter: (p) =>
          statusNamesObj[getStatus(p.row?.status_id)?.name ?? ""],
        // flex: isMobile ? undefined : 1,
        renderCell: (p) => {
          return (
            <StatusChip
              statusId={p.row.status_id}
              pill
              small
              sx={{ textTransform: undefined }}
            />
          );
        },
      },
      {
        field: "session_time",
        headerName: "Session Date",
        width: isPageSubSection ? 65 : 75,
        renderCell: (p) => {
          const startTime = p.row?.session_start_time;
          const date = startTime && new Date(startTime);
          const cancelledSession = sessionBlocked.includes(
            p.row.session_status_id,
          );
          return (
            <>
              {date ? (
                <Typography variant="subtitle2" fontSize={14} fontWeight={500}>
                  {cancelledSession ? (
                    <s>{formatDate(date, "M.d.yy")}</s>
                  ) : (
                    formatDate(date, "M.d.yy")
                  )}
                </Typography>
              ) : null}
            </>
          );
        },
      },
      {
        field: "created_at",
        headerName: "Created At",
        type: "dateTime",
        valueGetter: (v) => {
          return new Date(v.row.created_at);
        },
        width: isPageSubSection ? 115 : 125,
        renderCell: (p) => {
          const created = p.row?.created_at;
          const date = created && new Date(created);
          return (
            <>
              {date ? (
                <Typography variant="subtitle2" fontSize={14} fontWeight={500}>
                  {formatDate(date, "M.d.yy")} &middot;{" "}
                  {formatDate(date, "h:mm a")}
                </Typography>
              ) : null}
            </>
          );
        },
      },
      {
        field: "updated_at",
        headerName: "Last Updated",
        type: "dateTime",
        valueFormatter(v) {
          if (v.value) {
            return formatDistanceToNow(new Date(v.value), { addSuffix: true });
          }
          return null;
        },
        width: isPageSubSection ? 115 : 125,
      },
      {
        field: "latest_chat.created_at",
        headerName: "Last Comment",
        width: 200,
        valueGetter(v) {
          return v.row?.latest_chat?.created_at;
        },
        valueFormatter(v) {
          if (v.value) {
            return formatDistanceToNow(new Date(v.value), { addSuffix: true });
          }
          return null;
        },
        renderCell: (p) => {
          if (!p.row.latest_chat?.message) {
            return null;
          }
          if (!p.value) {
            return null;
          }
          const commenter = getUser(p.row.latest_chat.user_id);
          const value =
            (commenter
              ? `${commenter.first_name} ${commenter.last_name}: `
              : "") + p.row.latest_chat.message;
          return (
            <Tooltip title={stripHtml(value).result}>
              <Typography variant="subtitle2" fontSize={14} fontWeight={500}>
                {formatDistanceToNow(new Date(p.value), { addSuffix: true })}
              </Typography>
            </Tooltip>
          );
        },
        filterable: false,
        sortable: false,
      },
      {
        field: "client_assignee",
        headerName: "Client Assignee",
        width: isPageSubSection ? 80 : 100,
        type: "singleSelect",
        valueOptions: users,
        getOptionLabel,
        getOptionValue,
      },
      {
        field: "reported_by",
        headerName: "Created by",
        type: "singleSelect",
        valueOptions: users,
        getOptionLabel,
        getOptionValue,
      },
      {
        field: "priority",
        headerName: "Priority",
        width: isPageSubSection ? 60 : 100,
        type: "singleSelect",
        valueFormatter: ({ value }) => capitalizeSentence(value),
        valueOptions: ["normal", "high", "critical"],
      },
      {
        field: "time_spent",
        headerName: "Time Spent",
        sortable: false,
        filterable: false,
        type: "number",
        align: "left",
        headerAlign: "left",
        renderCell: (p) => {
          if (!p.value) return null;
          return (
            <Stack
              width={"100%"}
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
            >
              <ClockIcon color={palette.secondary[300]} size={16} />
              <Typography
                color={palette.secondary[600]}
                fontWeight={600}
                ml={0.5}
                fontSize={12}
              >
                {convertToHoursAndMinutes(p.value, true)}
              </Typography>
            </Stack>
          );
        },
      },
      ...(!inIndividualOrg && !isPageSubSection ? attachmentsAndComments : []),
      {
        field: "assigned_to",
        headerName: "Assigned To",

        width: isPageSubSection ? 115 : 125,
        type: "singleSelect",
        valueOptions: users,
        getOptionLabel,
        getOptionValue,
      },
      {
        field: "action_pending",
        headerName: "Awaiting",
        type: "singleSelect",
        width: 60,
        valueOptions: [
          { value: "admire", label: "Admire" },
          { value: "client", label: "Client" },
        ],
        renderCell(p) {
          return (
            <ToggleActionPendingButton
              justShowOne
              action_pending={p.value as "admire" | "client" | undefined}
            />
          );
        },
      },
      ...(!isPageSubSection ? [] : attachmentsAndComments),
      {
        field: "internal_priority",
        headerName: "Internal Priority",
        flex: isMobile ? undefined : 1,
        width: 50,
        type: "singleSelect",
        valueOptions: ["low", "medium", "high"],
        renderCell: (p) => (
          <PriorityChip
            type="internal"
            priority={p.row.internal_priority}
            edge="pill"
          />
        ),
      },
      {
        field: "requested_by_date",
        headerName: "Due date",
        flex: isMobile ? undefined : 1,
        width: isPageSubSection ? 80 : 100,
        renderCell: (p) => {
          const requestedBy = p.row?.requested_by_date;
          const date = requestedBy ? new Date(requestedBy) : "";
          return (
            <>
              {date ? (
                <Typography variant="subtitle2" fontSize={14} fontWeight={500}>
                  {formatDate(date, "M.d.yy")}
                </Typography>
              ) : (
                ""
              )}
            </>
          );
        },
      },
      ...(!inIndividualOrg || isPageSubSection ? [] : attachmentsAndComments),
      {
        field: "deleted_at",
        headerName: "",
        filterable: false,
        flex: isMobile ? undefined : 1,
        renderCell: (p) => RestoreButton(p, archived, restoreTask, "task"),
      },
    ] as (GridColDef | undefined)[]);
  }, [
    aAndCProps,
    isMobile,
    topics,
    orgs,
    users,
    statuses,
    getStatus,
    inIndividualOrg,
    isPageSubSection,
    attachmentsAndComments,
    pageRendered,
  ]);
}

export const announcementColumns = (
  isMobile: boolean,
): GridColDef<GetAnnouncementsApiResponse["rows"][0]>[] => [
  {
    field: "orgs.name",
    headerName: "Organization",
    renderCell: (p) => {
      const [first, ...rest] = p?.row?.orgs?.filter(Boolean) ?? [];
      const orgNames = p?.row?.orgs?.map((o) => o.name).filter(Boolean) ?? [];

      return (
        <Tooltip title={orgNames.join(", ")}>
          <span>
            {p.row.is_global
              ? "All"
              : first?.name + (rest.length ? ` +${rest.length}` : "")}
          </span>
        </Tooltip>
      );
    },
    minWidth: 100,
    flex: isMobile ? undefined : 1.5,
    filterable: false,
    sortable: false,
  },
  {
    field: "status",
    headerName: "Status",
    width: 200,
    renderCell(r) {
      const now = Date.now();
      const start_date = new Date(r.row.start_date).getTime();
      const end_date = new Date(r.row.end_date).getTime();
      const status =
        start_date > now
          ? { text: "scheduled", shade: "tertiary" }
          : end_date <= now
          ? { text: "expired", shade: "lightGrey" }
          : { text: "active", shade: "green" };
      return (
        <ChipStyled
          edge="badge"
          shade={status.shade as "tertiary" | "lightGrey" | "green"}
          type="accent"
          label={status.text}
          sx={{
            textTransform: "uppercase",
            height: 24,
            fontSize: 12,
            width: "auto",
            p: 0,
          }}
        />
      );
    },
    flex: isMobile ? undefined : 1,
    filterable: false,
    sortable: false,
  },
  {
    field: "start_date",
    headerName: "Start Date",
    type: "dateTime",
    valueFormatter: (v) =>
      v && typeof v.value === "string"
        ? format(new Date(v.value), "M.d.yy")
        : "",
    flex: isMobile ? undefined : 1,
  },
  {
    field: "end_date",
    headerName: "End Date",
    type: "dateTime",
    valueFormatter: (v) =>
      v && typeof v.value === "string"
        ? format(new Date(v.value), "M.d.yy")
        : "",
    flex: isMobile ? undefined : 1,
  },
  {
    field: "created_by_user.last_name",
    headerName: "Created By",
    valueGetter(v) {
      return (
        v.row.created_by_user.first_name + " " + v.row.created_by_user.last_name
      );
    },
    flex: isMobile ? undefined : 1,
    filterable: false,
    sortable: false,
  },
  {
    field: "announcement",
    headerName: "Announcement",
    flex: isMobile ? undefined : 5,
    renderCell: (p) => {
      const announcement = p.row.announcement;
      return (
        <Tooltip title={announcement}>
          <span>{announcement}</span>
        </Tooltip>
      );
    },
  },
];

export function useUsersColumns(
  mode: "admin" | "client",
  userType: "admire" | "client",
) {
  const isMobile = useMobile();
  const { positions } = usePositionsList();
  const { orgs, getOrg } = useOrgsList(
    !(userType === "client" && mode === "admin"),
  );
  return useMemo(() => {
    const userCols: (
      | GridColDef<GetUsersApiResponse["rows"][number]>
      | undefined
      | false
    )[] = [
      {
        field: "Avatar",
        headerName: "",
        filterable: false,
        sortable: false,
        flex: isMobile ? undefined : 1,
        renderCell: (r: GridRenderCellParams) => {
          const user = r.row;
          return (
            <UserAvatar
              first_name={user?.first_name || ""}
              last_name={user?.last_name || ""}
              id={user?.id}
              dimensions={24}
              textProps={{ fontSize: 12 }}
              badgeStyles={{ width: 14, height: 14, right: -12, top: 1 }}
            />
          );
        },
        maxWidth: 50,
      },
      {
        field: "first_name",
        headerName: "First Name",
        flex: isMobile ? undefined : 1,
        renderCell: (r: GridRenderCellParams) => {
          const user = r.row;
          return (
            <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
              {user.title} {user.first_name}
            </Typography>
          );
        },
        minWidth: 100,
      },
      {
        field: "last_name",
        headerName: "Last Name",
        flex: isMobile ? undefined : 1,
        minWidth: 100,
      },
      userType === "client" &&
        mode === "admin" &&
        ({
          field: "orgs.id",
          headerName: "Organization",
          valueGetter: (params: any) => {
            return params.row.orgs?.[0].id;
          },
          minWidth: 250,
          type: "singleSelect",
          valueOptions: orgs.map((o) => ({ value: o.id, label: o.name })),
          sortable: false,
          renderCell: (p) => {
            const orgs = p.row.orgs;
            return (
              <Tooltip
                title={orgs.map((o) => (
                  <Fragment key={o.id}>
                    <Link
                      component={RouterLink}
                      to={`/organizations/${o.id}?tab=users`}
                      color={"inherit"}
                    >
                      <span>{getOrg(o.id)?.name}</span>
                    </Link>
                    <br />
                  </Fragment>
                ))}
              >
                <span>
                  {getOrg(orgs[0].id)?.name +
                    (orgs.length > 1 ? " +" + (orgs.length - 1) : "")}
                </span>
              </Tooltip>
            );
          },
        } as GridColDef<GetUsersApiResponse["rows"][number]>),
      emailColumn,
      {
        field: "phones.phone_number",
        headerName: "Phone",
        //flex: isMobile ? undefined : 1,
        renderCell: (r: GridRenderCellParams) => (
          <List>
            {r.row.phones.map(
              (p: GetUserApiResponse["phones"][number], i: number) => (
                <ListItemText
                  key={i}
                  primary={`${p.type.toUpperCase()}: +${
                    p.country_code
                  } (${p.phone_number.substring(
                    0,
                    3,
                  )}) ${p.phone_number.substring(
                    3,
                    6,
                  )}-${p.phone_number.substring(6)}${
                    p.extension ? `x${p.extension}` : ""
                  }`}
                  primaryTypographyProps={{
                    fontSize: 12,
                    fontWeight: 500,
                  }}
                />
              ),
            )}
          </List>
        ),
        width: 200,
        filterable: false,
        sortable: false,
      },
    ].filter(Boolean);
    if (userType === "client") {
      userCols.push({
        field: "orgs.user_roles.position_id",
        headerName: "Position",
        flex: isMobile ? undefined : 1,
        valueGetter: (params) => {
          return params.row.orgs?.[0].user_roles?.[0].position_id;
        },
        type: "singleSelect",
        valueOptions: positions.map((p) => ({ value: p.id, label: p.name })),
        minWidth: 100,
        sortable: false,
      });
    }
    userCols.push({
      field: "permissions",
      headerName: "Permissions",
      flex: isMobile ? undefined : 1,
      valueGetter: (params) => {
        const firstOrgId = params.row.orgs?.[0].id;
        const roles =
          params.row?.orgs
            ?.find((org) => org.id === firstOrgId)
            ?.user_roles.map((ur) => ur.role_id) || [];
        return GetRolesPermissions(roles);
      },
      minWidth: 100,
      filterable: false,
      sortable: false,
    });
    if (mode === "admin") {
      if (userType === "client") {
        userCols.push(
          {
            field: "availability",
            headerName: "Availability",
            flex: isMobile ? undefined : 1,
            minWidth: 100,
          },
          {
            field: "internal_notes",
            headerName: "Notes",
            type: "boolean",
            flex: isMobile ? undefined : 1,
            valueGetter: (params) => {
              return !!params.row.internal_notes;
            },
            valueFormatter: ({ value }) => {
              return Boolean(value);
            },
            renderCell: ({ value }) =>
              value !== true ? null : <CheckCircleFilled />,
            minWidth: 100,
            filterable: false,
            sortable: false,
          },
        );
      } else {
        userCols.push({
          field: "orgs.user_roles.role_id",
          headerName: "Roles",
          flex: isMobile ? undefined : 1,
          valueGetter: (params) =>
            params.row.orgs?.[0].user_roles
              .map((r) => getRoleInfoById(r.role_id).name)
              .join(", "),
          minWidth: 100,
          filterable: false,
          sortable: false,
        });
      }
    } else {
      userCols.push(
        {
          field: "roles.internal_name1",
          headerName: "Admin",
          type: "boolean",
          flex: isMobile ? undefined : 1,
          valueGetter: (params) => {
            return validateRoles(
              params.row.orgs?.[0].user_roles.map(
                (ur) => getRoleInfoById(ur.role_id).internal_name,
              ),
              EXTERNAL_ADMINS,
            );
          },
          valueFormatter: ({ value }) => {
            return Boolean(value);
          },
          renderCell: ({ value }) =>
            value !== true ? null : <CheckCircleFilled />,
          minWidth: 100,
          filterable: false,
          sortable: false,
        },
        {
          field: "roles.internal_name2",
          headerName: "Admire Coordinator",
          type: "boolean",
          flex: isMobile ? undefined : 1,
          valueGetter: (params) => {
            return params.row.orgs?.[0].user_roles.find(
              (ur) =>
                getRoleInfoById(ur.role_id).internal_name ===
                "admire_coordinator",
            );
          },
          valueFormatter: ({ value }) => {
            return Boolean(value);
          },
          renderCell: ({ value }) =>
            value !== true ? null : <CheckCircleFilled />,
          minWidth: 100,
          filterable: false,
          sortable: false,
        },
      );
    }
    if (userType === "admire") {
      userCols.push({
        field: "calendly_link",
        headerName: "Calendly link",
        flex: isMobile ? undefined : 1,
        sortable: false,
      } as GridColDef<GetUserApiResponse>);
    }
    userCols.push({
      field: "created_at",
      headerName: "Created on",
      flex: isMobile ? undefined : 1,
      minWidth: 150,
      renderCell: (p: GridRenderCellParams) => {
        const created = p.row?.created_at;
        const date = created && new Date(created);
        return (
          <>
            {date ? (
              <Typography variant="subtitle2" fontSize={14} fontWeight={500}>
                {formatDate(date, "M.d.yy")} &middot;{" "}
                {formatDate(date, "h:mm a")}
              </Typography>
            ) : null}
          </>
        );
      },
    });
    if (mode === "admin") {
      userCols.push(
        {
          field: "password",
          headerName: "Registered",
          type: "boolean",
          flex: isMobile ? undefined : 1,
          valueGetter: (params: GridRenderCellParams) => {
            return !!params.row.has_password;
          },
          valueFormatter: ({ value }) => {
            return Boolean(value);
          },
          renderCell: ({ value }) =>
            value !== true ? null : <CheckCircleFilled />,
          minWidth: 100,
        },
        {
          field: "last_login",
          headerName: "Last Activity",
          flex: isMobile ? undefined : 1,
          type: "dateTime",
          valueFormatter(v) {
            if (v.value) {
              return formatDistanceToNow(new Date(v.value), {
                addSuffix: true,
              });
            }
            return null;
          },
        },
        {
          field: "activity",
          headerName: "Activity",
          filterable: false,
          sortable: false,
          flex: isMobile ? undefined : 1,
          renderCell: (r: GridRenderCellParams) => {
            const userId = r.row.id;
            return <UserActivityButton id={userId} />;
          },
        },
      );
    }
    return userCols.filter(Boolean) as GridColDef<GetUserApiResponse>[];
  }, [isMobile, positions, orgs, userType, mode]);
}

export const rolesColumns: GridColDef[] = [
  idColumn,
  { field: "first_name", headerName: "First Name", width: 150 },
  { field: "last_name", headerName: "Last Name", width: 150 },
  { field: "email", headerName: "Email", width: 200 },
  { field: "login_attempts", headerName: "Login Count", width: 120 },
  {
    field: "last_login",
    headerName: "Last Login",
    width: 180,
    valueFormatter: (v) =>
      v && typeof v.value === "string" ? format(new Date(v.value), "Pp") : "",
  },
  { field: "last_failed_login", headerName: "Last Failed Login", width: 150 },
  {
    field: "roles",
    headerName: "Roles",
    width: 200,
    renderCell: (params) => params.value.map((r: any) => r.name).join(", "),
  },
  // {
  //   field: "actions",
  //   headerName: "Actions",
  //   width: 150,
  //   renderCell: ConfirmDelete,
  // },
];

export const useSessionColumns: () => GridColDef[] = () => {
  const isMobile = useMobile();
  const statuses = useStatusesList("session");

  return useMemo(() => {
    return [
      {
        field: "title",
        headerName: "title",
        flex: isMobile ? undefined : 1,
        valueGetter: (r) => r.row?.title || "Consulting Session",
      },
      {
        field: "booking.start_time",
        headerName: "Date",
        flex: isMobile ? undefined : 1,
        renderCell: (p) => {
          const startTime = p.row?.booking?.start_time;
          const date = startTime && new Date(startTime);
          return (
            <>
              {date ? (
                <Typography variant="subtitle2" fontSize={14} fontWeight={500}>
                  {formatDate(date, "M.d.yy")}
                </Typography>
              ) : null}
            </>
          );
        },
      },
      {
        field: "booking.start_time2",
        headerName: "Time",
        flex: isMobile ? undefined : 1,
        sortable: false,
        filterable: false,
        renderCell: (p) => {
          const startTime = p.row?.booking?.start_time;
          const date = startTime && new Date(startTime);
          return (
            <>
              {date ? (
                <Typography variant="subtitle2" fontSize={14} fontWeight={500}>
                  {formatDate(date, "h:mm a")}
                </Typography>
              ) : null}
            </>
          );
        },
      },
      {
        field: "booking.name",
        headerName: "Duration",
        flex: isMobile ? undefined : 1,
        maxWidth: 100,
        valueGetter: (p) => {
          const durationFromName = p.row?.booking?.booking_type?.duration ?? 0;
          const duration = isNaN(durationFromName)
            ? p.row.booking?.duration
            : durationFromName;
          return `${duration ?? 0} Min.`;
        },
      },
      {
        field: "booking.join_url",
        headerName: "Zoom Link",
        flex: isMobile ? undefined : 1,
        maxWidth: 100,
        filterable: false,
        sortable: false,
        renderCell: (p) => {
          const booking = p?.row?.booking;
          return (
            <Stack direction={"row"} alignItems={"center"}>
              {booking?.play_url ? (
                <Link
                  href={`${booking?.play_url}?pwd=${booking?.meeting_password}`}
                  sx={{ pl: 2, pr: 0 }}
                  target="_blank"
                >
                  Recording
                </Link>
              ) : (
                <JoinZoomMeetingButton booking={booking} />
              )}
            </Stack>
          );
        },
      },
      {
        field: "org.name",
        headerName: "Organization",
        flex: isMobile ? undefined : 1,
        valueGetter: (p) => p.row?.org?.name ?? "",
      },
      {
        field: "status_id",
        headerName: "Status",
        type: "singleSelect",
        flex: isMobile ? undefined : 1,
        valueOptions: statuses.statuses,
        getOptionLabel,
        getOptionValue,
        renderCell: (p) => {
          return (
            <StatusChip
              statusId={p.row.status_id}
              pill
              small
              sx={{ textTransform: undefined }}
            />
          );
        },
      },
      {
        field: "tasks",
        headerName: "Number of tasks",
        flex: isMobile ? undefined : 1,
        valueGetter: (p) => p.row?.tasks?.length ?? 0,
      },
      {
        field: "session_invitees",
        headerName: "Attendees",
        flex: isMobile ? undefined : 1,
        valueGetter: (p) => p.row?.session_invitees?.length ?? 0,
      },
      {
        field: "created_by_user.first_name",
        headerName: "Created By",
        filterable: false,
        flex: isMobile ? undefined : 1,
        valueGetter: (p) => {
          const user = p.row.reported_by_user;
          return `${user?.first_name ?? ""} ${user?.last_name ?? ""}`;
        },
      },
      {
        field: "assigned_to_user.first_name",
        headerName: "Consultant",
        filterable: false,
        flex: isMobile ? undefined : 1,
        valueGetter: (p) => {
          const user = p.row?.assigned_to_user;
          return `${user?.first_name ?? ""} ${user?.last_name ?? ""}`;
        },
      },
    ];
  }, [isMobile, statuses]);
};

export const reportsColumns: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    minWidth: 180,
    flex: 1,
  },
  {
    field: "role_id",
    headerName: "Permissions",
    flex: 1,
    valueGetter: (params) => {
      return (
        params.row.report_permissions
          .map((r: { role_id: number }) => getRoleInfoById(r.role_id).name)
          .join(", ") ?? ""
      );
    },
    minWidth: 100,
  },
  {
    field: "actions",
    headerName: "",
    minWidth: 100,
    width: 200,
    align: "right",
    headerAlign: "right",
    renderCell: ReportActions,
  },
];
export const orgInfoColumns = (isMobile: boolean): GridColDef[] => {
  const { accountManagers } = useAccountManagersList();
  const { licenseStatuses } = useLicensesList();
  const { getStatusByPhase } = useStatusesList();
  const blocked = getStatusByPhase("blocked");
  return useMemo(
    () => [
      {
        field: "name",
        headerName: "Organization",
        flex: isMobile ? undefined : 1.5,
        filterable: false,
      },
      {
        field: "openTasks",
        headerName: "Open Tasks",
        flex: isMobile ? undefined : 1,
        filterable: false,
      },
      {
        field: "unreadMessages",
        headerName: "Unread Comments",
        flex: isMobile ? undefined : 1,
        filterable: false,
      },
      {
        field: "previousSession",
        headerName: "Previous Session",
        flex: isMobile ? undefined : 1,
        valueGetter: (v) => {
          const nextDate = v.row.previousSession;
          if (!nextDate) return "";
          return formatDate(new Date(nextDate), "M.d.yy");
        },
        renderCell: (p) => {
          const nextDate = p.row.previousSession;
          if (!nextDate) return "";
          const previousSessionStatus = p.row.previousSessionStatus;

          return blocked.includes(previousSessionStatus) ? (
            <s>{formatDate(new Date(nextDate), "M.d.yy")}</s>
          ) : (
            formatDate(new Date(nextDate), "M.d.yy")
          );
        },
        sortable: false,
        filterable: false,
      },
      {
        field: "nextSession",
        headerName: "Next Session",
        flex: isMobile ? undefined : 1,
        valueGetter: (v) => {
          const nextDate = v.row.nextSession;
          if (!nextDate) return "";
          return formatDate(new Date(nextDate), "M.d.yy");
        },
        renderCell: (p) => {
          const nextDate = p.row.nextSession;
          if (!nextDate) return "";
          const nextSessionStatus = p.row.nextSessionStatus;

          return blocked.includes(nextSessionStatus) ? (
            <s>{formatDate(new Date(nextDate), "M.d.yy")}</s>
          ) : (
            formatDate(new Date(nextDate), "M.d.yy")
          );
        },
        filterable: false,
      },
      {
        field: "scheduledSessions",
        headerName: "Scheduled Sessions",
        flex: isMobile ? undefined : 1,
        filterable: false,
      },

      {
        field: "account_manager_id",
        headerName: "Account manager",
        flex: isMobile ? undefined : 1,
        type: "singleSelect",
        valueOptions: accountManagers,
        getOptionLabel,
        getOptionValue,
      },
      {
        field: "license_status_id",
        headerName: "Status",
        flex: isMobile ? undefined : 1,
        type: "singleSelect",
        valueOptions: licenseStatuses,
        getOptionLabel,
        getOptionValue,
      },
    ],
    [isMobile, accountManagers, licenseStatuses],
  );
};
export const useReviewTimerColumns: (isMobile: boolean) => GridColDef[] = (
  isMobile,
) => {
  return useMemo(() => {
    return [
      {
        field: "org.name",
        headerName: "Organization",
        flex: isMobile ? undefined : 1,
        valueGetter: (p) => p.row?.org?.name ?? "",
      },
      {
        field: "start_time",
        headerName: "Start time",
        type: "dateTime",
        valueGetter: (v) => {
          return new Date(v.row.start_time);
        },
        flex: isMobile ? undefined : 1,
        minWidth: 250,
        renderCell: (p) => {
          const start_time = p.row?.start_time;
          const date = start_time && new Date(start_time);
          return (
            <>
              {date ? (
                <Typography variant="subtitle2" fontSize={14} fontWeight={500}>
                  {formatDate(date, "M.d.yy")} &middot;{" "}
                  {formatDate(date, "h:mm a")}
                </Typography>
              ) : null}
            </>
          );
        },
      },
      {
        field: "end_time",
        headerName: "End time",
        type: "dateTime",
        valueGetter: (v) => {
          return new Date(v.row.end_time);
        },
        flex: isMobile ? undefined : 1,
        minWidth: 250,
        renderCell: (p) => {
          const end_time = p.row?.end_time;
          const date = end_time && new Date(end_time);
          return (
            <>
              {date ? (
                <Typography variant="subtitle2" fontSize={14} fontWeight={500}>
                  {formatDate(date, "M.d.yy")} &middot;{" "}
                  {formatDate(date, "h:mm a")}
                </Typography>
              ) : null}
            </>
          );
        },
      },
      {
        field: "duration",
        headerName: "Duration",
        flex: isMobile ? undefined : 1,
        minWidth: 100,
        renderCell: (p) => {
          const duration = Number(p.row?.duration?.toFixed(0) ?? 0);
          return (
            <>
              {duration ? (
                <Typography variant="subtitle2" fontSize={14} fontWeight={500}>
                  {`${duration ?? 0} Min.`}
                </Typography>
              ) : null}
            </>
          );
        },
      },
      {
        field: "notes",
        headerName: "Note",
        flex: isMobile ? undefined : 1,
        valueGetter: (p) => p.row?.notes ?? "",
      },
      {
        field: "is_manual",
        headerName: "Manually entered",
        type: "boolean",
        flex: isMobile ? undefined : 1,
        renderCell: ({ value }) =>
          value !== true ? null : <CheckCircleFilled />,
        minWidth: 100,
        filterable: false,
        sortable: false,
      },
      {
        field: "status",
        headerName: "Reviewed",
        type: "string",
        flex: isMobile ? undefined : 1,
        renderCell: ({ value }) =>
          value !== "reviewed" ? null : <CheckCircleFilled />,
        minWidth: 100,
        filterable: false,
        sortable: false,
      },
    ];
  }, []);
};

export const useMediaColumns: (
  isMobile: boolean,
  rerender?: boolean,
) => GridColDef[] = (isMobile, rerender) => {
  const [_, setQuery] = useQueryParam<number>("media");
  const [mt, _m] = useQueryParam<"custom_work" | "all">("mediaTab");
  const [vt, _v] = useQueryParam<"image" | "list">("viewType");

  return useMemo(
    () => [
      {
        field: "media.filename",
        headerName: "File",
        flex: isMobile ? undefined : 1,
        renderCell: (v) => {
          const imgClick = useCallback(() => {
            if (v.row?.media_id) {
              setQuery(Number(v.row?.media_id ?? 0));
            }
          }, [v.row?.media_id, mt, vt]);

          return (
            <Button
              sx={{ display: "flex", alignItems: "flex-end", gap: 0.5, pl: 0 }}
              onClick={imgClick}
            >
              <FileIcon size={16} /> {v.row?.media?.filename}
            </Button>
          );
        },
      },
      {
        field: "media.mime_type",
        headerName: "Type",
        flex: isMobile ? undefined : 1,
        valueGetter: (v) => v.row.media.mime_type,
      },
      {
        field: "task_id",
        headerName: "Task",
        flex: isMobile ? undefined : 1,
        renderCell: (v) => {
          const taskId = v.row.task_id;
          const task = v.row.task;
          return (
            !!taskId &&
            !!task && (
              <OpenTaskButton
                taskId={taskId}
                code={task.code}
                title={task.title}
              />
            )
          );
        },
      },
      {
        field: "media.created_at",
        headerName: "Date uploaded",
        flex: isMobile ? undefined : 1,
        renderCell: (v) => {
          return (
            !!v.row?.media?.created_at && (
              <Typography variant="subtitle1">
                {formatDate(
                  new Date(v.row?.media?.created_at),
                  "MM/dd/yyyy hh:mm aa",
                )}
              </Typography>
            )
          );
        },
      },
      {
        field: "actions",
        headerName: "",
        minWidth: 150,
        align: "right",
        headerAlign: "right",
        filterable: false,
        sortable: false,
        renderCell: TaskMediaActions,
      },
    ],
    [isMobile, rerender, mt, vt],
  );
};

export const useAdminTimesColumns: (
  isMobile: boolean,
) => GridColDefPremium[] = (isMobile) => {
  return useMemo(
    () =>
      [
        {
          field: "consultant",
          headerName: "Consultant",
          flex: isMobile ? undefined : 1,
        },
        {
          field: "org_name",
          headerName: "Organization",
          flex: isMobile ? undefined : 1,
        },
        {
          field: "timer_type",
          headerName: "Timer type",
          flex: isMobile ? undefined : 1,
        },
        {
          field: "task_title",
          headerName: "Task",
          flex: isMobile ? undefined : 1,
          renderCell: (v) => {
            const { rowNode, row } = v;
            const taskId = row.task_id;
            const title = row.task_title;
            const code = row.task_code;
            if (rowNode.type !== "leaf") {
              if (
                rowNode.type === "group" &&
                rowNode?.groupingField === "task_title"
              ) {
                return rowNode?.groupingKey ?? "";
              }

              return null;
            }
            return (
              !!taskId &&
              !!title && (
                <OpenTaskButton taskId={taskId} code={code} title={title} />
              )
            );
          },
        },
        {
          field: "start_time",
          headerName: "Date",
          type: "date",
          flex: isMobile ? undefined : 1,
          valueGetter: (v: any) => {
            return v ? new Date(v) : undefined;
          },
          valueFormatter: (v: any) =>
            v?.row ? new Date(v?.row).toLocaleDateString() : null,
          renderCell: (v) => {
            const { rowNode, row } = v;
            const start_time = row?.start_time;
            const end_time = row?.end_time;
            if (rowNode.type !== "leaf") {
              if (
                rowNode.type === "group" &&
                rowNode?.groupingField === "task_title"
              ) {
                return rowNode?.groupingKey ?? "";
              }

              return null;
            }
            return start_time ? (
              <Stack direction={"row"} alignItems={"center"} height={"100%"}>
                <Typography
                  fontSize={14}
                  fontWeight={500}
                  px={isMobile ? 0 : 1}
                >
                  {formatDate(new Date(start_time), "MM/dd/yyyy · h:mm aaa")} -{" "}
                  {end_time ? formatDate(new Date(end_time), "h:mm aaa") : ""}
                </Typography>
              </Stack>
            ) : null;
          },
        },
        {
          field: "duration_date",
          headerName: "Manual entry date",
          type: "date",
          flex: isMobile ? undefined : 1,
          valueGetter: (v: any) => {
            return v ? new Date(v) : undefined;
          },
          valueFormatter: (v: any) =>
            v?.row ? new Date(v?.row).toLocaleDateString() : null,
          renderCell: (v) => {
            const { rowNode, row } = v;
            const duration_date = row?.duration_date;

            if (rowNode.type !== "leaf") {
              if (
                rowNode.type === "group" &&
                rowNode?.groupingField === "duration_date"
              ) {
                return rowNode?.groupingKey
                  ? formatDate(
                      new Date(
                        fixJsFormattedDate(rowNode?.groupingKey as string),
                      ),
                    )
                  : "";
              }

              return null;
            }
            return duration_date ? (
              <Stack direction={"row"} alignItems={"center"} height={"100%"}>
                <Typography fontSize={14} fontWeight={500}>
                  {formatDate(new Date(fixJsFormattedDate(duration_date)))}
                </Typography>
              </Stack>
            ) : null;
          },
        },
        {
          field: "activities",
          headerName: "User activities",
          flex: isMobile ? undefined : 1,
          groupable: false,
          renderCell: (v) => {
            const { rowNode, row } = v;
            const activities = row.activities;

            if (
              rowNode.type !== "leaf" ||
              JSON.stringify(activities) === "[null]"
            ) {
              return null;
            }

            const activityButtons = activities
              .flat()
              .map(
                ({
                  task_id,
                  code,
                  title,
                }: {
                  task_id?: number;
                  code?: string;
                  title?: string;
                }) => {
                  return (
                    !!task_id &&
                    !!code &&
                    !!title && (
                      <OpenTaskButton
                        taskId={task_id}
                        code={code}
                        title={title}
                        ellipsis={false}
                      />
                    )
                  );
                },
              );
            return (
              !!activities.length && (
                <MuiTooltip
                  title={
                    <Stack
                      direction={"column"}
                      sx={{ bgcolor: "#FFF" }}
                      gap={0.5}
                    >
                      {activityButtons}
                    </Stack>
                  }
                  PopperProps={{
                    placement: "top-start",
                  }}
                  slotProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#FFF",
                        boxShadow: "0 1px 12px 2px  rgba(99,100,105,0.2)",
                      },
                    },
                  }}
                >
                  <Box component={"span"} maxWidth={300}>
                    {activityButtons}
                  </Box>
                </MuiTooltip>
              )
            );
          },
        },
        {
          flex: isMobile ? undefined : 1,
          field: "time_spent",
          headerName: "Time spent (minutes)",
          type: "number",
          groupable: false,
        },
      ] satisfies GridColDefPremium[],
    [isMobile],
  );
};
