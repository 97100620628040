import {
  EXTERNAL,
  InternalName,
  GetRoleId,
  getRoleInfoById,
  yup,
  INTERNAL,
  useLocation,
  GetRoleById,
} from "../../lib";
import {
  useDeleteUserMutation,
  useCreateUserMutation,
  useUpdateUserMutation,
  GetUserApiResponse,
} from "../../state/rtk-query/state/user";
import {
  useDeleteOrgUserMutation,
  useUpdateUserProfileInOrgMutation,
} from "../../state/rtk-query/state/organization";
import {
  GetOrgUserByIdApiResponse,
  useAddUserToOrgMutation,
  useUpdateUserInOrgMutation,
  AddUserToOrgApiArg,
} from "../../state/rtk-query/state/organization";
import { useMobile } from "../../themes";
import { useGetPositionsQuery } from "../../state/rtk-query/state/positions";
import { BaseForm, initValue } from "./BaseForm";
import { extractDigits } from "../inputs/InputField";
import {
  ArrayField,
  CheckboxWithLabelField,
  InputField,
  OptionalRadioGroupField,
  PhoneWithCountryCodeField,
  RadioGroupField,
  SelectField,
  TopLabel,
} from "./FormFields";
import { useMemo, useState } from "react";
import { useOrgId, useOrgsList } from "../hooks";
import { useDispatch, useSelector } from "react-redux";
import { authSelectors, uiActions } from "../../state";
import {
  useAuthForgotPasswordMutation,
  useAuthInviteEmailMutation,
} from "../../state/rtk-query/state/auth";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import { DeReactivateButton } from "../buttons/DeReactivateButton";
import { useImpersonation } from "../hooks/useImpersonate";
import { useTurnOffUserTimerMutation } from "../../state/rtk-query/state/user";
import { timezoneNames } from "../../lib/timezones";
import { Tooltip, TransferWithinAStation } from "../icons";

function GridItem(props: Parameters<typeof Grid>[0]) {
  const xs =
    typeof props.md === "number" && props.md <= 6 ? props.md * 2 : props.md;
  return <Grid item xs={xs} {...props} />;
}

type Title = "Rabbi" | "Mr." | "Mrs." | "Ms." | "Dr." | "Rebbetzin";
const titles = ["Rabbi", "Mr.", "Mrs.", "Ms.", "Dr.", "Rebbetzin"];
const phoneTypes = ["Office", "Cell", "Home"];
const userTypes = [
  { id: "admire", name: "Admire user" },
  { id: "client", name: "Client user" },
];

const phoneSchema = yup.object({
  number: yup
    .string()
    .required("Number required")
    .test(
      "contains-7-10-digits",
      "Number must contain 7 to 10 digits",
      (value) => {
        const digits = extractDigits(value || "");
        return digits.length >= 7 && digits.length <= 10;
      },
    ),
  extension: yup.string().matches(/^\d*$/, "Only numbers").nullable(),
});

const orgsSchema = yup.object({
  id: yup.number().test({
    test: (value, { options: { context } }) => {
      return !!(context?.userType === "admire" || (value && value > 0));
    },
    message: "Organization is required",
  }),
  position_id: yup.number(),
  roles: yup
    .array(yup.string().required())
    .min(1, "Select at least 1 role")
    .required(),
});

const validationSchema = yup.object({
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  email: yup
    .string()
    .email("A valid email is required")
    .required("Email is required"),
  phones: yup
    .array(phoneSchema)
    .min(1, "At least 1 phone is required")
    .required(),
  calendly_link: yup.string(),
  position_id: yup.number(),
  defaultOrgId: yup.number(),
  orgs: yup.array(orgsSchema).min(1, "At least 1 organization is required"),
  timezone: yup.string().required("Timezone is required"),
});
interface Props<Mode extends "admin" | "client"> {
  onClose: () => void;
  editUser?: Mode extends "admin"
    ? GetUserApiResponse
    : GetOrgUserByIdApiResponse;
  mode: Mode;
  userType?: Mode extends "admin" ? "admire" | "client" | undefined : never;
  orgId?: number;
  isUpdateProfile?: boolean;
}

export const AddUserForm = <Mode extends "admin" | "client">({
  onClose,
  editUser,
  mode,
  userType,
  orgId: _orgId,
  isUpdateProfile = false,
}: Props<Mode>) => {
  const { query } = useLocation();
  const dispatch = useDispatch();
  const isAdmin = useSelector(authSelectors.isAdmin);
  const isInternal = useSelector(authSelectors.isInternal);
  const impersonate = useImpersonation();

  const [emailSent, setEmailSent] = useState(false);
  const timerIsActive = editUser?.timer_is_active;
  const rolesNames = useSelector(authSelectors.rolesNames);
  const isNotSuperAdmin = !rolesNames.includes("super_admin");
  const userIsSuperAdmin = editUser?.orgs?.find((org) =>
    org.user_roles.find((ur) => ur.role_id === 1),
  );
  const [inactive, setInactive] = useState(!!(editUser as any)?.deleted_at);
  const disableEditing = !!(userIsSuperAdmin && isNotSuperAdmin) || inactive;
  const isMobile = useMobile();
  const rolesToUse = useMemo(
    () => ({
      admire: INTERNAL.map((role) => role.toUpperCase()),
      client: EXTERNAL.map((role) => role.toUpperCase()),
    }),
    [],
  );
  const id = editUser?.id;

  const orgId = useOrgId(_orgId);

  const { data: positions } = useGetPositionsQuery();
  const { orgs } = useOrgsList(mode === "client" || userType === "admire");

  const initialUserType = initValue(
    userType ||
      (editUser?.orgs?.find((org) =>
        org.user_roles?.find(
          ({ role_id }) => getRoleInfoById(role_id).type === "internal",
        ),
      )
        ? "admire"
        : "client"),
  );
  const initialOrgId = initValue(mode === "admin" ? query?.orgId : orgId);

  const initialValues = {
    userType: initialUserType,
    title: initValue(editUser?.title),
    first_name: initValue(editUser?.first_name),
    last_name: initValue(editUser?.last_name),
    email: query?.email || initValue(editUser?.email),
    availability: initValue(editUser?.availability),
    calendly_link: initValue((editUser as GetUserApiResponse)?.calendly_link),
    mfa_required: initValue(editUser?.mfa_required, false),
    internal_notes: initValue(mode === "admin" ? editUser?.internal_notes : ""),
    phones: initValue(
      (editUser?.phones?.length ? editUser?.phones : undefined)?.map(
        (phone) => ({
          number: phone.phone_number,
          type:
            phone.type === "mobile"
              ? "Cell"
              : phone.type === "home"
              ? "Home"
              : "Office",
          cc: phone.country_code,
          extension: initValue(phone.extension),
          sms: !!phone.sms,
          id: initValue(phone.id),
        }),
      ),
      [
        {
          cc: "1",
          number: "",
          type: "Cell",
          sms: true,
          extension: "",
        },
      ],
    ),
    orgs: initValue(
      editUser?.orgs?.map((org) => ({
        id: org.id,
        position_id: initValue(org.user_roles[0]?.position_id),
        roles: org.user_roles.map((ur) =>
          GetRoleById(ur.role_id)?.toUpperCase(),
        ),
      })),
      [
        {
          id: initialOrgId,
          position_id: initValue(
            editUser?.orgs?.[0].user_roles[0]?.position_id,
          ),
          roles: [],
        },
      ],
    ),
    defaultOrgId: initValue(editUser?.default_org_id),
    timezone: initValue(editUser?.timezone, "America/New_York"),
  };
  const [submitUser] = useCreateUserMutation();
  const [updateUser] = useUpdateUserMutation();
  const [updateUserProfile] = useUpdateUserProfileInOrgMutation();
  const [submitOrgUser] = useAddUserToOrgMutation();
  const [updateOrgUser] = useUpdateUserInOrgMutation();
  const [deleteUser] = useDeleteUserMutation();
  const [deleteOrgUser] = useDeleteOrgUserMutation();
  const [recoverPassword] = useAuthForgotPasswordMutation();
  const [sendInviteEmail] = useAuthInviteEmailMutation();
  const [turnOffTimer] = useTurnOffUserTimerMutation();

  const handleSubmit = async ({
    userType,
    phones: _phones,
    availability,
    email,
    first_name,
    internal_notes,
    last_name,
    mfa_required,
    title,
    calendly_link,
    orgs,
    defaultOrgId,
    timezone,
  }: typeof initialValues) => {
    if (!(userIsSuperAdmin && isNotSuperAdmin)) {
      const phones = _phones
        .filter((phone) => phone.number)
        .map((phone) => ({
          id: phone.id,
          country_code: phone.cc,
          phone_number: extractDigits(phone.number),
          extension: phone.extension,
          type:
            phone.type === "Cell"
              ? "mobile"
              : (phone.type.toLowerCase() as "mobile" | "home" | "office"),
          sms: !!phone.sms,
        }));
      const userOrgs = orgs.map((org) => ({
        id: userType === "admire" ? -1 : org.id!,
        user_roles: org.roles.map((role) => ({
          position_id: org?.position_id || undefined,
          role_id: GetRoleId(role as Uppercase<InternalName>),
        })),
      }));
      const body: AddUserToOrgApiArg["body"] & { org_id?: number } = {
        id: id || 0,
        email,
        first_name,
        last_name,
        phones,
        mfa_required,
        orgs: userOrgs,
        availability,
        default_org_id: defaultOrgId,
        internal_notes,
        calendly_link,
        timezone,
      };
      if (title) body.title = title as Title;
      if (
        mode === "admin" &&
        userType === "admire" &&
        (!id || !!(editUser as any)?.deleted_at)
      ) {
        await submitUser({ body });
      } else if (mode === "admin" && userType === "admire" && id) {
        await updateUser({ id, body });
      } else if (
        mode === "admin" &&
        userType === "client" &&
        (!id || !!(editUser as any)?.deleted_at)
      ) {
        await submitUser({
          body,
        });
      } else if (mode === "admin" && userType === "client" && id) {
        await updateUser({ id, body });
      } else if (mode === "client" && userType === "client" && !id && orgId) {
        await submitOrgUser({ body, orgId });
      } else if (mode === "client" && userType === "client" && id) {
        if (isUpdateProfile) {
          await updateUserProfile({ orgId: orgId!, body });
        } else {
          await updateOrgUser({ id, orgId: orgId!, body });
        }
      }
    }
    return onClose();
  };

  const turnOffUserTimer = async () => {
    if (
      editUser?.id &&
      (userType === "admire" || (isInternal && isUpdateProfile))
    ) {
      await turnOffTimer({
        id: editUser.id,
        deactivateTimer: !timerIsActive,
      });
      dispatch(
        uiActions.showSuccess(`Timer turned ${timerIsActive ? "off" : "on"}`),
      );
      onClose();
    }
  };

  return (
    <BaseForm
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      onClose={onClose}
      enableReinitialize
      disableEditing={disableEditing}
      additionalButtons={
        !!id && (
          <>
            {!(userIsSuperAdmin && isNotSuperAdmin) && !isUpdateProfile && (
              <DeReactivateButton
                entity="user"
                isActive={!disableEditing}
                handleClick={(type) => {
                  if (type === "de") {
                    mode === "admin"
                      ? deleteUser({ id })
                      : orgId
                      ? deleteOrgUser({ id, orgId })
                      : null;
                    onClose();
                  } else {
                    setInactive(false);
                  }
                }}
              />
            )}
            {userType === "admire" && !isUpdateProfile ? (
              <Button
                color={timerIsActive ? "error" : "success"}
                onClick={turnOffUserTimer}
                fullWidth={!isUpdateProfile}
              >
                {timerIsActive ? `Turn off timer` : "Turn on timer"}
              </Button>
            ) : null}
            {!inactive &&
              !isUpdateProfile &&
              (!emailSent ? (
                <Button
                  color="secondary"
                  sx={{
                    whiteSpace: "nowrap",
                    pr: 0,
                  }}
                  onClick={() => {
                    setEmailSent(true);
                    if (!editUser.has_password) {
                      sendInviteEmail({ body: { email: editUser.email } });
                    } else {
                      recoverPassword({ body: { email: editUser.email } });
                    }
                  }}
                >
                  {!editUser.has_password
                    ? "Send Invite Email"
                    : "Send Reset Email"}
                </Button>
              ) : (
                <Typography sx={{ mt: 1.3, ml: 2 }} variant="body2">
                  Email Sent
                </Typography>
              ))}
            {isAdmin && (
              <IconButton
                sx={{ pl: 5 }}
                color="warning"
                onClick={() => impersonate({ id })}
                disableRipple
                disableTouchRipple
              >
                <Tooltip title="Impersonate">
                  <TransferWithinAStation sx={{ fontSize: 22 }} />
                </Tooltip>
              </IconButton>
            )}
          </>
        )
      }
    >
      {({ setFieldValue, values, resetForm }) => {
        return (
          <Grid container rowSpacing={2} columnSpacing={1.5}>
            <UserTypeField
              isMobile={isMobile}
              mode={mode}
              resetForm={resetForm}
              setFieldValue={setFieldValue}
              userType={values.userType as "admire" | "client"}
              id={id}
            />
            <GridItem md={2}>
              <TopLabel label="Title">
                <SelectField
                  name="title"
                  items={titles}
                  fullWidth
                  allowNoSelection
                />
              </TopLabel>
            </GridItem>
            <GridItem md={4}>
              <TopLabel label="First name">
                <InputField name="first_name" />
              </TopLabel>
            </GridItem>
            <GridItem md={6}>
              <TopLabel label="Last name">
                <InputField name="last_name" />
              </TopLabel>
            </GridItem>
            <GridItem md={6}>
              <TopLabel label="Email">
                <InputField name="email" disabled={!!editUser} />
              </TopLabel>
            </GridItem>
            {isUpdateProfile ||
              (userType === "admire" && (
                <GridItem md={6}>
                  <TopLabel label="Timezone">
                    <SelectField name="timezone" items={timezoneNames} />
                  </TopLabel>
                </GridItem>
              ))}
            {!isUpdateProfile &&
              (mode === "client" || values.userType === "admire") && (
                <GridItem md={6}>
                  <TopLabel label="Roles">
                    <SelectField
                      name="orgs[0].roles"
                      items={rolesToUse[values.userType as "admire" | "client"]}
                      multiple
                    />
                  </TopLabel>
                </GridItem>
              )}
            {values.userType === "admire" && !isUpdateProfile && (
              <GridItem md={6}>
                <TopLabel label="Calendly link">
                  <InputField name="calendly_link" />
                </TopLabel>
              </GridItem>
            )}
            {mode === "client" &&
              values.userType === "client" &&
              !isUpdateProfile && (
                <GridItem md={6}>
                  <TopLabel label="Position">
                    <SelectField
                      name="orgs[0].position_id"
                      items={
                        (positions?.rows as { id: number; name: string }[]) ||
                        []
                      }
                      allowNoSelection
                    />
                  </TopLabel>
                </GridItem>
              )}
            {values.userType === "client" && !isUpdateProfile && (
              <GridItem md={6}>
                <TopLabel label="Availability">
                  <InputField name="availability" />
                </TopLabel>
              </GridItem>
            )}
            {values.userType === "client" && mode === "admin" && (
              <GridItem md={12}>
                <TopLabel label="Internal note">
                  <InputField name="internal_notes" />
                </TopLabel>
              </GridItem>
            )}
            {mode === "admin" && values.userType === "client" && (
              <ArrayField
                buttonName="Add org"
                itemInitialValue={{ id: "", roles: [], position_id: "" }}
                name="orgs"
                minLength={1}
                label="ORGANIZATIONS"
              >
                {({ DeleteItem }) => {
                  return values.orgs.map((org, index) => {
                    return (
                      <GridItem
                        container
                        rowSpacing={1}
                        columnSpacing={1.5}
                        key={`orgs[${index}]`}
                      >
                        <GridItem md={4.2} xs={12}>
                          <TopLabel label="Organization">
                            <SelectField
                              name={`orgs[${index}].id`}
                              items={orgs}
                            />
                          </TopLabel>
                        </GridItem>
                        <GridItem md={3} xs={12}>
                          <TopLabel label="Roles">
                            <SelectField
                              name={`orgs[${index}].roles`}
                              items={
                                rolesToUse[
                                  values.userType as "admire" | "client"
                                ]
                              }
                              multiple
                            />
                          </TopLabel>
                        </GridItem>
                        <GridItem md={2} xs={6}>
                          <TopLabel label="Position">
                            <SelectField
                              name={`orgs[${index}].position_id`}
                              items={
                                (positions?.rows as {
                                  id: number;
                                  name: string;
                                }[]) || []
                              }
                              allowNoSelection
                            />
                          </TopLabel>
                        </GridItem>
                        <GridItem md={2.8} xs={6}>
                          <TopLabel label="">
                            <GridItem container rowSpacing={0}>
                              <GridItem md={true}>
                                <OptionalRadioGroupField
                                  name={`defaultOrgId`}
                                  items={[
                                    {
                                      id: values.orgs[index].id || 0,
                                      name: "Default",
                                    },
                                  ]}
                                />
                              </GridItem>
                              <GridItem md={"auto"}>
                                <DeleteItem index={index} />
                              </GridItem>
                            </GridItem>
                          </TopLabel>
                        </GridItem>
                      </GridItem>
                    );
                  });
                }}
              </ArrayField>
            )}
            <ArrayField
              buttonName="Add phone"
              itemInitialValue={{
                cc: "1",
                number: "",
                type: "Office",
                sms: false,
              }}
              name="phones"
              label={isUpdateProfile ? "" : "PHONE"}
              minLength={1}
            >
              {({ DeleteItem }) =>
                values.phones.map((phone, index) => (
                  <GridItem
                    container
                    rowSpacing={1}
                    columnSpacing={1.5}
                    key={`phones[${index}]`}
                  >
                    <GridItem md={4.5} xs={8}>
                      <TopLabel label="Phone">
                        <PhoneWithCountryCodeField
                          name={`phones[${index}].number`}
                          countryCodeName={`phones[${index}].cc`}
                        />
                      </TopLabel>
                    </GridItem>
                    <GridItem md={2}>
                      <TopLabel label="Ext">
                        <InputField name={`phones[${index}].extension`} />
                      </TopLabel>
                    </GridItem>
                    <GridItem md={3}>
                      <TopLabel label="Type">
                        <SelectField
                          name={`phones[${index}].type`}
                          items={phoneTypes}
                        />
                      </TopLabel>
                    </GridItem>
                    <GridItem md={2.5} xs={6}>
                      <TopLabel label="">
                        <GridItem container rowSpacing={0}>
                          <GridItem md={true}>
                            <CheckboxWithLabelField
                              label="SMS"
                              name={`phones[${index}].sms`}
                            />
                          </GridItem>
                          <GridItem md={"auto"}>
                            <DeleteItem index={index} />
                          </GridItem>
                        </GridItem>
                      </TopLabel>
                    </GridItem>
                  </GridItem>
                ))
              }
            </ArrayField>
            {!isUpdateProfile && userType === "client" && (
              <GridItem md={6}>
                <TopLabel label="Timezone">
                  <SelectField name="timezone" items={timezoneNames} />
                </TopLabel>
              </GridItem>
            )}
            <GridItem md={6}>
              <TopLabel label="">
                <CheckboxWithLabelField
                  name="mfa_required"
                  label="Require 2FA"
                />
              </TopLabel>
            </GridItem>
            {isUpdateProfile && editUser && editUser.orgs.length > 1 && (
              <GridItem md={12}>
                <TopLabel
                  label="DEFAULT ORG"
                  typographyProps={{
                    color: "#ACAEB6",
                    mt: 1,
                    sx: {
                      fontWeight: 600,
                    },
                  }}
                >
                  <OptionalRadioGroupField
                    items={editUser.orgs
                      .filter((org) => !!org.name)
                      .map((org) => ({ id: org.id, name: org.name! }))}
                    name="defaultOrgId"
                  />
                </TopLabel>
              </GridItem>
            )}
          </Grid>
        );
      }}
    </BaseForm>
  );
};

type UserTypeFieldProps = {
  mode: "admin" | "client";
  id?: number;
  isMobile: boolean;
  userType: "admire" | "client";
  resetForm: () => void;
  setFieldValue: any;
};
function UserTypeField({
  mode,
  id,
  isMobile,
  userType,
  resetForm,
  setFieldValue,
}: UserTypeFieldProps) {
  const UTField = useMemo(
    () => (
      <Grid item md={12}>
        <RadioGroupField
          name="userType"
          row
          items={userTypes}
          spacing={isMobile ? undefined : 4}
          onChange={(e) => {
            if (userType !== e.target.value) {
              resetForm();
              setFieldValue("userType", e.target.value);
            }
          }}
        />
      </Grid>
    ),
    [isMobile, resetForm, setFieldValue, userType],
  );
  if (mode !== "admin" || id) return null;
  return UTField;
}
