import { Close, Option, OptionObj } from "./types";
import { DropDownButton } from "./DropDownButton";
import { useParallelState } from "./useParallelState";
import { DropDownItem } from "./DropDownItem";
import { capitalizeSentence } from "../../../lib";

export function useMenuItems<
  O extends Option,
  M extends boolean | undefined = undefined,
>(params: Omit<DropDownButton.Props<O, M>, "children">, close: Close) {
  const {
    items,
    itemProps,
    rerenderKey,
    multiple,
    capitalize,
    additionalItems,
    RenderItem,
  } = params;
  const [selected, setSelected] = useParallelState(
    params.selected,
    params.setSelected,
  );

  return (additionalItems ? [...items, ...additionalItems] : items).map((i) => {
    const { id, name, deleted_at } = (
      typeof i === "string"
        ? {
            id: i,
            name: capitalize ? capitalizeSentence(i.split("_").join(" ")) : i,
          }
        : i
    ) as OptionObj<O>;
    const isSelected = Array.isArray(selected)
      ? selected.includes(id)
      : selected === id;
    const iProps =
      itemProps &&
      itemProps(
        {
          value: id,
          name,
          isSelected,
          setSelected,
          deleted_at,
          close,
          item: i,
        },
        i,
      );
    const rk = `${rerenderKey}${iProps?.rerenderKey}`;
    const renderItem = RenderItem && (
      <RenderItem
        value={id}
        name={name}
        isSelected={isSelected}
        setSelected={setSelected}
        deleted_at={deleted_at}
        close={close}
        item={i}
      />
    );
    return (
      <DropDownItem
        {...iProps}
        name={name}
        key={id}
        value={id}
        multiple={multiple}
        isSelected={isSelected}
        setSelected={setSelected}
        close={close}
        rerenderKey={rk}
        capitalize={capitalize}
        deleted_at={deleted_at}
      >
        {typeof RenderItem === "function"
          ? renderItem
          : iProps?.children ?? name}
      </DropDownItem>
    );
  });
}
