import Mention from "@tiptap/extension-mention";
import Placeholder from "@tiptap/extension-placeholder";
import Underline from "@tiptap/extension-underline";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { Link } from "@tiptap/extension-link";

import { suggestionRender } from "../inputs";
import { DependencyList, Dispatch, SetStateAction, useMemo } from "react";
import HardBreak from "@tiptap/extension-hard-break";
import { ImagesPlugin } from "../plugins";
interface Props {
  placeholder?: string;
  suggestions?: string[] | { id: number | string; name: string }[];
  defaultText?: string;
  dependencies?: DependencyList;
  setAttachments?: Dispatch<SetStateAction<File[]>>;
}

export { type Props as UseCustomEditorProps };

export const useCustomEditor = ({
  placeholder,
  suggestions = [],
  defaultText,
  dependencies,
  setAttachments,
}: Props) => {
  const HandleImagesPlugin = useMemo(() => {
    return ImagesPlugin({ setAttachments });
  }, [setAttachments, ImagesPlugin]);
  return useEditor(
    {
      extensions: [
        StarterKit.configure({ hardBreak: false }),
        Link.configure().extend({ inclusive: false }), // stops link from staying active after pasting or setting
        Placeholder.configure({ placeholder: placeholder || "" }),
        Underline.configure(),
        HardBreak.extend({
          //https://stackoverflow.com/a/71439294
          addKeyboardShortcuts() {
            return {
              Enter: () => {
                const midList =
                  this.editor.isActive("bulletList") ||
                  this.editor.isActive("orderedList");
                return !midList && this.editor.commands.setHardBreak();
              },
            };
          },
        }),
        Mention.configure({
          HTMLAttributes: {
            class: "mention",
          },
          suggestion: {
            ...suggestionRender(suggestions),
          },
          renderHTML: ({ node }) => {
            const id =
              typeof node.attrs.id === "string"
                ? node.attrs.id
                : node.attrs.id?.id;
            const name =
              typeof node.attrs.id === "string"
                ? node.attrs.id
                : node.attrs.id?.name;
            return [
              "span",
              {
                class: "mention",
                "data-type": "mention",
                "data-mention_id": id,
              },
              `@${name}`,
            ];
          },
        }),
        HandleImagesPlugin,
      ],
      content: defaultText || "",
    },
    dependencies,
  );
};
