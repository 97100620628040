import { yup } from "../../lib";
import {
  GetOrgsApiResponse,
  useCreateOrgMutation,
  useUpdateOrgMutation,
  useDeleteOrgMutation,
  useGetLicensesAndStatusesQuery,
} from "../../state/rtk-query/state/organization";
import { useMobile } from "../../themes";
import { BaseForm, initValue } from "./BaseForm";
import { memo, useEffect, useMemo, useState } from "react";
import { useGetConsultantsAndAmsQuery } from "../../state/rtk-query/state/user";
import { extractDigits } from "../inputs/InputField";
import {
  FormContainer,
  FormItem,
  InputField,
  PhoneWithCountryCodeField,
  SelectField,
  TopLabel,
  ArrayField,
} from "./FormFields";
import { DeReactivateButton } from "../buttons/DeReactivateButton";
import { authSelectors, useSelector } from "../../state";

import { useLazyGetQuickbooksCustomerNamesQuery } from "../../state/rtk-query/state/quickbooks";
import { Button } from "@mui/material";
import { QuickbooksLoginModal } from "../modal";

function _QuickbooksCustomersDropdown({
  quickbooks_customer_id,
}: {
  quickbooks_customer_id?: string;
}) {
  const [getQuickbooksCustomerNames, { data }] =
    useLazyGetQuickbooksCustomerNamesQuery();
  const [open, setOpen] = useState(false);
  if (data) {
    return (
      <SelectField
        name="quickbooks_customer_id"
        items={data.data}
        allowNoSelection
      />
    );
  }
  return (
    <>
      <Button variant="contained" onClick={() => setOpen(true)}>
        {quickbooks_customer_id
          ? "Edit quickbooks customer"
          : "Link to quickbooks customer"}
      </Button>
      <QuickbooksLoginModal
        open={open}
        handleClose={() => setOpen(false)}
        apiCall={getQuickbooksCustomerNames}
      />
    </>
  );
}

const QuickbooksCustomersDropdown = memo(_QuickbooksCustomersDropdown);

const qlSchema = yup.object({
  label: yup.string().required("Title is required"),
  link: yup.string().required("URL is required"),
});

const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
  code: yup
    .string()
    .matches(/^[a-zA-Z0-9]*$/, "Only letters and numbers")
    .length(4, "Code must be 4 characters")
    .required("Code is required"),
  website: yup.string(),
  address: yup.string(),
  license_id: yup.number().required("License is required"),
  license_status_id: yup.number().required("Status is required"),
  consultant_id: yup.number(),
  sub_consultant_id: yup.number(),
  account_manager_id: yup.number(),
  it_info: yup.string(),
  internal_notes: yup.string(),
  quickbooks_customer_id: yup.string(),
  phone: yup
    .string()
    .test(
      "contains-7-10-digits",
      "Number must contain 7 to 10 digits",
      (value) => {
        if (!value) return true;
        const digits = extractDigits(value || "");
        return digits.length >= 7 && digits.length <= 10;
      },
    ),
  quickLinks: yup.array(qlSchema),
});

interface Props {
  onClose: () => void;
  editOrg?: GetOrgsApiResponse["rows"][0];
}

export const AddOrgForm = ({ onClose, editOrg }: Props) => {
  const isMobile = useMobile();
  const isAdmin = useSelector(authSelectors.isAdmin);
  const { data: { licenses } = { licenses: [] } } =
    useGetLicensesAndStatusesQuery();

  const [createOrg, { isSuccess }] = useCreateOrgMutation();
  const [updateOrg, { isSuccess: isSuccessUpdate }] = useUpdateOrgMutation();
  const success = isSuccess || isSuccessUpdate;
  useEffect(() => {
    if (success) {
      onClose();
    }
  }, [success]);
  const [deleteOrg] = useDeleteOrgMutation();
  const { id } = editOrg ?? {};
  const license_status_id = initValue(editOrg?.license_status_id);
  const license_id = initValue(
    license_status_id &&
      licenses.find((l) =>
        l.license_statuses.find((ls) => ls.id === license_status_id),
      )?.id,
  );

  const initialValues = {
    name: initValue(editOrg?.name),
    code: initValue(editOrg?.code),
    website: initValue(editOrg?.website),
    phone: initValue(editOrg?.phone),
    country_code: initValue(editOrg?.country_code, "1"),
    address: initValue(editOrg?.address),
    license_id,
    license_status_id,
    consultant_id: initValue(editOrg?.consultant_id),
    sub_consultant_id: initValue(editOrg?.sub_consultant_id),
    account_manager_id: initValue(editOrg?.account_manager_id),
    it_info: initValue(editOrg?.it_info),
    internal_notes: initValue(editOrg?.internal_notes),
    quickbooks_customer_id: initValue(editOrg?.quickbooks_customer_id),
    quickLinks: initValue(editOrg?.organization_links, [
      {
        label: "Admire Academy",
        link: "https://admire.document360.io",
      },
      {
        label: "Admire Community",
        link: "https://community.admirepro.com",
      },
      {
        label: "Admire Unlimited",
        link: "https://client.wvd.microsoft.com",
      },
    ]),
  };

  const handleSubmit = async (values: typeof initialValues) => {
    const { quickbooks_customer_id, quickLinks, ...rest } = values;
    const body = {
      ...rest,
      ...(isAdmin ? { quickbooks_customer_id } : {}),
      quick_links: quickLinks,
    };
    id ? updateOrg({ orgId: id, body }) : createOrg({ body });
  };

  const {
    data: { consultant, account_manager } = {
      consultant: [],
      account_manager: [],
    },
  } = useGetConsultantsAndAmsQuery();
  const consultants = useMemo(
    () =>
      consultant.map((c) => ({
        id: c.id,
        name: `${c.first_name} ${c.last_name}`,
      })),
    [consultant],
  );
  const ams = useMemo(
    () =>
      account_manager.map((am) => ({
        id: am.id,
        name: `${am.first_name} ${am.last_name}`,
      })),
    [account_manager],
  );
  const [inactive, setInactive] = useState(!!editOrg?.deleted_at);

  return (
    <BaseForm
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      onClose={onClose}
      enableReinitialize
      disableEditing={inactive}
      additionalButtons={
        !!id && (
          <DeReactivateButton
            entity="org"
            isActive={!inactive}
            handleClick={(type) => {
              if (type === "de") {
                deleteOrg({ orgId: id });
                onClose();
              } else {
                setInactive(false);
              }
            }}
          />
        )
      }
    >
      {({ setFieldValue, values }) => {
        const licensesStatuses = useMemo(() => {
          const license = licenses.find((l) => {
            return l.id === +values.license_id;
          });
          if (!license) return [];
          if (
            !license.license_statuses.find(
              (ls) => ls.id === +values.license_status_id,
            )
          ) {
            setFieldValue("license_status_id", "");
          }
          return license.license_statuses;
        }, [values.license_id]);

        return (
          <FormContainer>
            <FormItem>
              <FormItem>
                <TopLabel label="Name">
                  <InputField name="name" />
                </TopLabel>
              </FormItem>
              <FormItem xs={5} sm={2} md={2}>
                <TopLabel label="Org code">
                  <InputField
                    name="code"
                    placeholder="ABCD"
                    sx={{
                      "::placeholder": {
                        fontStyle: "italic",
                      },
                    }}
                    disabled={!!editOrg}
                  />
                </TopLabel>
              </FormItem>
            </FormItem>
            <FormItem flexDirection={isMobile ? "column" : "row"}>
              <TopLabel label="Website">
                <InputField name="website" />
              </TopLabel>
              <TopLabel label="Phone">
                <PhoneWithCountryCodeField
                  countryCodeName="country_code"
                  name="phone"
                />
              </TopLabel>
            </FormItem>
            <FormItem flexDirection={isMobile ? "column" : "row"}>
              <TopLabel label="Address">
                <InputField name="address" />
              </TopLabel>
            </FormItem>
            <FormItem flexDirection={isMobile ? "column" : "row"}>
              <TopLabel label="License">
                <SelectField
                  name="license_id"
                  items={licenses}
                  datatype="number"
                />
              </TopLabel>
              <TopLabel label="Status">
                <SelectField
                  name="license_status_id"
                  items={licensesStatuses}
                  datatype="number"
                />
              </TopLabel>
            </FormItem>
            <FormItem flexDirection={isMobile ? "column" : "row"}>
              <TopLabel label="Consultant">
                <SelectField
                  name="consultant_id"
                  items={consultants}
                  allowNoSelection
                />
              </TopLabel>
              <TopLabel label="Sub Consultant">
                <SelectField
                  name="sub_consultant_id"
                  items={consultants}
                  allowNoSelection
                />
              </TopLabel>
            </FormItem>
            <FormItem flexDirection={isMobile ? "column" : "row"}>
              <TopLabel label="Account Manager">
                <SelectField
                  name="account_manager_id"
                  items={ams}
                  allowNoSelection
                />
              </TopLabel>
              <TopLabel label="IT info">
                <InputField name="it_info" />
              </TopLabel>
            </FormItem>
            <FormItem flexDirection={isMobile ? "column" : "row"}>
              <TopLabel label="Internal note">
                <InputField name="internal_notes" />
              </TopLabel>
            </FormItem>
            {isAdmin && (
              <FormItem flexDirection={isMobile ? "column" : "row"}>
                <TopLabel label="Quickbooks customer">
                  <QuickbooksCustomersDropdown
                    quickbooks_customer_id={values.quickbooks_customer_id}
                  />
                </TopLabel>
              </FormItem>
            )}

            <ArrayField
              name="quickLinks"
              buttonName="Add Quick Link"
              itemInitialValue={{
                label: "",
                link: "",
                editable: true,
              }}
              label="Quick Links"
            >
              {({ DeleteItem }) =>
                values.quickLinks.map((ql, index) => (
                  <FormItem
                    key={index}
                    flexDirection={isMobile ? "column" : "row"}
                    gap={isMobile ? 0 : 2}
                  >
                    <TopLabel label="Title">
                      <InputField name={`quickLinks[${index}].label`} />
                    </TopLabel>
                    <TopLabel label="URL">
                      <FormItem>
                        <InputField name={`quickLinks[${index}].link`} />
                        <DeleteItem index={index} />
                      </FormItem>
                    </TopLabel>
                  </FormItem>
                ))
              }
            </ArrayField>
          </FormContainer>
        );
      }}
    </BaseForm>
  );
};
