import { Stack, Typography, useTheme, Link } from "@mui/material";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { authSelectors } from "../../state";
import {
  useGetOrgTasksInProgressQuery,
  useGetMyTasksInProgressQuery,
} from "../../state/rtk-query/state/tasks";
import { useMobile, useTablet } from "../../themes";
import { StatusCard, StatusContent } from "../cards";
import {
  CommentCritical,
  CommentLines,
  CommentInfo,
  CommentDots,
  WrenchIcon,
  BuildingIcon,
  UserIcon,
  StackIcon,
  ClipboardIcon,
  ListIcon,
} from "../icons";
import { castZero } from "../../lib";
import { useStatusesList } from "../hooks/useLists";
import { ChipStyled } from "../styled";
import { generateUrl } from "../../lib";
import admireIconOrange from "../../assets/img/AdmireIconOrange.svg";

interface Props {
  orgId?: number;
  orgCount?: number;
  isConsultantDash?: boolean;
  isNewAdmire?: boolean;
}

export function TaskStats({
  orgId,
  orgCount,
  isConsultantDash,
  isNewAdmire,
}: Props) {
  const { palette } = useTheme();
  const isMobile = useMobile();
  const isTablet = useTablet();
  const isSmallerThanPc = isMobile || isTablet;
  const isInternal = useSelector(authSelectors.isInternal);
  const isCW = useSelector(authSelectors.isCustomWorkUser);
  const isConsultant = useSelector(authSelectors.isConsultant);
  const isDeveloper = useSelector(authSelectors.isDeveloper);
  const userId = useSelector(authSelectors.userId);

  const { data: tasksInProgress, isLoading } =
    orgId !== undefined
      ? useGetOrgTasksInProgressQuery(
          { orgId },
          { refetchOnMountOrArgChange: true, skip: orgId < 1 },
        )
      : useGetMyTasksInProgressQuery(undefined, {
          skip: !isInternal,
          refetchOnMountOrArgChange: true,
        });

  const baseTo = isInternal ? "" : "/tasks";

  const {
    awaitingClientCount,
    awaitingConsultantCount,
    assignedToMeCount,
    openTasksCount,
    customWorkCount,
    unscheduledCount,
    backlogCount,
    newCommentsCount,
    criticalQuestionsCount,
    newTasksCount,
    unassignedQueueCount,
    awaitingAdmireCount,
  } = tasksInProgress ?? {};

  const view = isInternal && isConsultantDash ? "task" : undefined;
  const assignedToMe = [userId];
  const allAssignees = isConsultant || isCW ? ([] as number[]) : undefined;
  const unassigned = [-1];
  const cwType = isCW ? ["custom_work"] : undefined;

  const { statuses } = useStatusesList("all_tasks");
  const newStatuses = [
    ...new Set(
      statuses
        .filter((s) => s.transition_phase === "todo")
        .map((s) => s.internal_name),
    ),
  ];

  const uqc = castZero(unassignedQueueCount);

  function genUrl(query: Record<string, any>) {
    return generateUrl(baseTo, {
      query: { view, taskAssignees: allAssignees, taskType: cwType, ...query },
    });
  }
  return (
    <StatusCard
      sx={{
        width: "100%",
        "&:last-child": {
          pb: isSmallerThanPc ? 1 : 0,
        },
      }}
      header={
        <Stack
          direction={isSmallerThanPc ? "column" : "row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
          gap={1}
        >
          <Stack direction={isMobile ? "column" : "row"} gap={isMobile ? 1 : 4}>
            <Typography variant="h4">
              TASKS IN PROGRESS
              {castZero(openTasksCount) > 0 ? <> &middot;</> : ""}{" "}
              {openTasksCount ? (
                <Link
                  component={RouterLink}
                  to={genUrl({})}
                  fontSize={14}
                  fontWeight={600}
                >
                  {openTasksCount}
                </Link>
              ) : null}
            </Typography>
            {!!castZero(unscheduledCount) && !isDeveloper && (
              <Typography variant="h4">
                PLANNING &middot;{" "}
                <Link
                  component={RouterLink}
                  to={genUrl({ taskType: ["unscheduled"] })}
                  fontSize={14}
                  fontWeight={600}
                >
                  {castZero(unscheduledCount)}
                </Link>
              </Typography>
            )}
          </Stack>

          {!isLoading && isInternal && (isNewAdmire || isConsultantDash) ? (
            <Link
              component={RouterLink}
              to={`?${
                isConsultantDash ? "view=task&" : ""
              }taskFilterMyOrgs=false&taskType[]=quick_question&taskAssignees[]=-1`}
            >
              <ChipStyled
                sx={{ p: 0 }}
                edge="badge"
                shade={
                  uqc > 50
                    ? "red"
                    : uqc > 25
                    ? "orange"
                    : uqc > 10
                    ? "yellow"
                    : uqc > 0
                    ? "secondary"
                    : "green"
                }
                type="accent"
                lighterText
                label={
                  <Stack direction={"row"} alignItems={"flex-end"} gap={1}>
                    <ListIcon size={22} />
                    <Typography fontSize={15} fontWeight={600}>
                      {castZero(unassignedQueueCount)} Task queue
                    </Typography>
                  </Stack>
                }
              />
            </Link>
          ) : (
            !!baseTo &&
            !!castZero(openTasksCount) && (
              <Link
                component={RouterLink}
                to={baseTo + "?taskOpenTasks=false"}
                fontSize={14}
                fontWeight={600}
              >
                View all
              </Link>
            )
          )}
        </Stack>
      }
    >
      {isCW && (
        <StatusContent
          Icon={StackIcon}
          value={`${castZero(backlogCount)}`}
          caption="Backlog"
          color={{ main: palette?.blue[500], 100: palette.blue[100] }}
          to={genUrl({ taskAssignees: unassigned })}
          action="backlog"
        />
      )}
      {isInternal && (
        <StatusContent
          Icon={ClipboardIcon}
          value={`${castZero(newTasksCount)}`}
          caption="New tasks"
          color={{ main: palette.grey[900], "100": palette.grey[200] }}
          to={genUrl({
            taskStatuses: newStatuses,
            taskAssignees: isCW ? assignedToMe : allAssignees,
            taskType: isConsultant
              ? ["quick_question", "unscheduled", "on_session"]
              : cwType,
          })}
          action="new_tasks"
        />
      )}
      {isInternal && (
        <StatusContent
          Icon={CommentLines}
          value={`${castZero(newCommentsCount)}`}
          color={palette.secondary}
          caption="New Comments"
          to={genUrl({
            newComments: true,
            taskOpenTasks: false,
            taskFilterMyOrgs: false,
            taskType: [],
          })}
          action="comments"
        />
      )}
      {isInternal ||
      castZero(awaitingConsultantCount) + castZero(awaitingClientCount) > 0 ? (
        <>
          {!isDeveloper && (
            <StatusContent
              Icon={CommentInfo}
              value={`${castZero(awaitingConsultantCount)}`}
              caption={isCW ? "Awaiting me" : "Awaiting Consultant"}
              color={palette.warning}
              to={genUrl({
                taskAwaiting: "admire",
                taskAssignees: isCW ? assignedToMe : allAssignees,
                taskType: isCW
                  ? cwType
                  : ["quick_question", "unscheduled", "on_session"],
              })}
              action="consultant"
            />
          )}
          <StatusContent
            Icon={CommentDots}
            value={`${castZero(awaitingClientCount)}`}
            caption="Awaiting Client"
            color={palette.info}
            to={genUrl({
              taskAwaiting: "client",
              taskAssignees: isCW ? assignedToMe : allAssignees,
              taskType: isConsultant
                ? ["quick_question", "unscheduled", "on_session"]
                : cwType,
            })}
            action="client"
          />
          {!isCW && isInternal && (
            <StatusContent
              Icon={() => <img src={admireIconOrange} alt="admire-icon" />}
              value={`${castZero(awaitingAdmireCount)}`}
              caption={"Awaiting Admire"}
              color={{ main: "#FCA05E", 100: "#FEEBDE" }}
              to={genUrl({
                taskAwaiting: "admire",
                taskAssignees: isCW ? assignedToMe : allAssignees,
              })}
            />
          )}
          {isInternal && !isCW && (
            <StatusContent
              Icon={CommentCritical}
              value={`${castZero(criticalQuestionsCount)}`}
              caption="Critical Tasks"
              color={palette.error}
              to={genUrl({
                taskPriority: ["critical"],
                taskType: ["quick_question", "unscheduled", "on_session"],
              })}
              action="critical"
            />
          )}
          {isInternal && !isCW && !isDeveloper && (
            <StatusContent
              Icon={WrenchIcon}
              value={`${castZero(customWorkCount)}`}
              caption="Custom Work"
              color={palette.primary}
              to={genUrl({ taskType: ["custom_work"] })}
              action="cw"
            />
          )}
          {isInternal && (isCW || isConsultantDash) && (
            <StatusContent
              Icon={isCW ? UserIcon : BuildingIcon}
              value={`${Number(
                isCW ? castZero(assignedToMeCount) : orgCount ?? 0,
              )}`}
              caption={isCW ? "Assigned to me" : "My organizations"}
              color={palette.success}
              to={
                isCW
                  ? genUrl({ taskAssignees: undefined, taskType: [] })
                  : baseTo
              }
              action="mine"
            />
          )}
        </>
      ) : isCW ? null : (
        <Stack direction={"column"} alignItems={"flex-start"}>
          <Typography variant="h3" color={palette.grey[900]}>
            Nothing to do here
          </Typography>
          <Typography variant="subtitle2">
            All your tasks have been resolved.
          </Typography>
        </Stack>
      )}
    </StatusCard>
  );
}
